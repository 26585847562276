<script>
import { layoutComputed } from "@/state/helpers";
import MetisMenu from "metismenujs/dist/metismenujs";

import { SimpleBar } from "simplebar-vue3";

/**
 * Sidebar component
 */
export default {
   mounted: function() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];``

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  components: {
     SimpleBar,
  },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "default":
              document.body.setAttribute("data-sidebar-size", "lg");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "md");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "icon":
              document.body.setAttribute("data-sidebar-size", "sm");
              break;
            default:
              document.body.setAttribute("data-sidebar-size", "lg");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-size", "fluid");
              break;
            default:
              document.body.setAttribute("data-layout-size", "fluid");
              break;
          }
        }
      },
    },
    color: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "light":
              document.body.setAttribute("data-sidebar", "light");
              break;
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              break;
            case "brand":
              document.body.setAttribute("data-sidebar", "brand");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
  },
  methods: {
    onRoutechange() {
      setTimeout(() => {
        if(document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if(this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      }, 300);
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
  },
};
</script>

<template>
<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">

    <!-- LOGO -->
    <div class="navbar-brand-box">
        <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
                <img src="@/assets/images/logo-dark-sm.png" alt="" height="22">
            </span>
            <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="22">
            </span>
        </router-link>

        <router-link to="/" class="logo logo-light">
            <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="22">
            </span>
            <span class="logo-sm">
                <img src="@/assets/images/logo-light-sm.png" alt="" height="22">
            </span>
        </router-link>
    </div>

    <button type="button" class="btn btn-sm px-3 header-item vertical-menu-btn topnav-hamburger" @click="toggleMenu">
        <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
        </span>
    </button>

    <SimpleBar data-simplebar class="sidebar-menu-scroll" ref="isSimplebar">

        <!--- Sidemenu -->
        <div id="sidebar-menu">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled" id="side-menu">
                <li class="menu-title" data-key="t-menu">Menu</li>
                <li>
                    <router-link class="side-nav-link-ref" to="/">
                        <eva-icon class="icon nav-icon" name="grid-outline"></eva-icon>
                        <span class="menu-item" data-key="t-calendar">Dashboard</span>
                    </router-link>
                </li>
                <li>
                    <a href="javascript: void(0);" class="has-arrow">
                        <eva-icon class="icon nav-icon" name="bar-chart-2-outline"></eva-icon>
                        <span class="menu-item" data-key="t-ecommerce">Analytics</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/products" data-key="t-products">Overview</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/product-detail" data-key="t-product-detail">Overall Report</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/orders" data-key="t-orders">Visitor Logs</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/customers" data-key="t-customers">Recent History</router-link></li>

                    </ul>
                </li>
                <li>
                    <a href="javascript: void(0);" class="has-arrow">
                        <eva-icon class="icon nav-icon" name="settings-2-outline"></eva-icon>
                        <span class="menu-item" data-key="t-ecommerce">Manage Site</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/products" data-key="t-products">Overview</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/product-detail" data-key="t-product-detail">Overall Report</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/orders" data-key="t-orders">Visitor Logs</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/customers" data-key="t-customers">Recent History</router-link></li>

                    </ul>
                </li>
                <li>
                    <a href="javascript: void(0);" class="has-arrow">
                        <eva-icon class="icon nav-icon" name="done-all-outline"></eva-icon>
                        <span class="menu-item" data-key="t-ecommerce">Subscription</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                        <li><router-link class="side-nav-link-ref" to="/subscription/subscriber" >Subscriber</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/subscription/plans">Plans</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/subscription/plan-inclusion" >Plan Inclusion</router-link></li>
                        <!-- <li><router-link class="side-nav-link-ref" to="/subscription/settings" >Settings</router-link></li> -->

                    </ul>
                </li>
                  <li>
                    <a href="javascript: void(0);" class="has-arrow">
                        <eva-icon class="icon nav-icon" name="briefcase-outline"></eva-icon>
                        <span class="menu-item" data-key="t-ecommerce">Job Management</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                        <li><router-link class="side-nav-link-ref" to="/job-management/posting" data-key="t-products">Job Posting</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/job-management/engagement" data-key="t-product-detail">Engagement</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/job-management/summary-report" data-key="t-orders">Summary Report</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/job-management/settings" >Setings</router-link>
      </li>

                    </ul>
                </li>
                 <li>
                    <a href="javascript: void(0);" class="has-arrow">
                        <eva-icon class="icon nav-icon" name="person"></eva-icon>
                        <span class="menu-item" data-key="t-ecommerce">Client</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/products" data-key="t-products">Overview</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/product-detail" data-key="t-product-detail">Overall Report</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/orders" data-key="t-orders">Visitor Logs</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/customers" data-key="t-customers">Recent History</router-link></li>

                    </ul>
                </li>
                <li>
                    <a href="javascript: void(0);" class="has-arrow">
                        <eva-icon class="icon nav-icon" name="people"></eva-icon>
                        <span class="menu-item" data-key="t-ecommerce">Virtual Assistant</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/products" data-key="t-products">Overview</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/product-detail" data-key="t-product-detail">Overall Report</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/orders" data-key="t-orders">Visitor Logs</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/ecommerce/customers" data-key="t-customers">Recent History</router-link></li>

                    </ul>
                </li>
                <li>
                    <router-link class="side-nav-link-ref" to="/calendar">
                        <eva-icon class="icon nav-icon" name="flag-outline"></eva-icon>
                        <span class="menu-item" data-key="t-calendar">System Report</span>
                    </router-link>
                </li>

                <li class="menu-title" data-key="t-applications">Applications</li>
              
                <li>
                    <router-link class="side-nav-link-ref" to="/calendar">
                        <eva-icon class="icon nav-icon" name="calendar-outline"></eva-icon>
                        <span class="menu-item" data-key="t-calendar">Calendar</span>
                    </router-link>
                </li>

                <li>
                    <router-link class="side-nav-link-ref" to="/chat">
                        <eva-icon class="icon nav-icon" name="message-circle-outline"></eva-icon>
                        <span class="menu-item" data-key="t-chat">Chat</span>
                        <span class="badge rounded-pill badge-soft-danger" data-key="t-hot">Hot</span>
                    </router-link>
                </li>

               
             
                <li>
                    <a href="javascript: void(0);" class="has-arrow">
                        <eva-icon class="icon nav-icon" name="email-outline"></eva-icon>
                        <span class="menu-item" data-key="t-email">Email</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                        <li><router-link class="side-nav-link-ref" to="/email/inbox" data-key="t-inbox">Inbox</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/email/read" data-key="t-read-email">Read Email</router-link></li>
                        <li>
                            <a href="javascript: void(0);">
                                <span data-key="t-email-templates">Templates</span>
                                <span class="badge rounded-pill badge-soft-success" data-key="t-new">New</span>
                            </a>
                            <ul class="sub-menu" aria-expanded="true">
                                <li><router-link class="side-nav-link-ref" to="/email/template-basic" data-key="t-basic-action">Basic Action</router-link></li>
                                <li><router-link class="side-nav-link-ref" to="/email/template-alert" data-key="t-alert-email">Alert Email</router-link></li>
                                <li><router-link class="side-nav-link-ref" to="/email/template-billing" data-key="t-bill-email">Billing Email</router-link></li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li>
                    <a href="javascript: void(0);" class="has-arrow">
                        <eva-icon class="icon nav-icon" name="book-outline"></eva-icon>
                        <span class="menu-item" data-key="t-invoices">Invoices</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                        <li><router-link class="side-nav-link-ref" to="/invoices/list" data-key="t-invoice-list">Invoice List</router-link></li>
                        <li><router-link class="side-nav-link-ref" to="/invoices/detail" data-key="t-invoice-detail">Invoice Detail</router-link></li>
                    </ul>
                </li>

          
              

               

            </ul>
        </div>
        <!-- Sidebar -->

        <div class="p-3 px-4 sidebar-footer">
            <p class="mb-1 main-title">{{new Date().getFullYear()}} &copy; Myprofile</p>
            <p class="mb-0">Developed by ProVA Philippines</p>
        </div>
    </SimpleBar>
</div>
<!-- Left Sidebar End -->
</template>
